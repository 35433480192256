.root {
  bottom: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 !important;
  position: absolute;
  top: 35px;
  width: 256px;
}

.arrow > a {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}

.arrow > a > span:first-of-type{
  text-align: left;
  padding-left: 6px;
}

.footer {
  padding: 0 16px 6px;
}

.menu {
  flex: 1;
}

.menu > a {
  border-radius: unset;
  min-height: 48px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.menu > a > span:first-of-type{
  text-align: left;
  padding-left: 6.4px; /*Default padding set 10px to point ICON*/
  padding-right: 11px;
}
