.logo {
  margin-right: 16px;
}

.expand {
  width: 15rem;
  padding-left: 6.4px;
}

.collapse {
  width: 4rem;
  padding-left: 4.59px;
}

@media only screen and (max-width: 767px) {
  .desktop {
    display: none !important;
  }

  .expand {
    width: 4rem;
    padding-left: 4.59px;
  }
}

@media only screen and (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}
 