.nav {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  box-shadow: 0px 0px 0px 1px rgba(16, 22, 26, 0.1),
    0px 1px 1px rgba(16, 22, 26, 0.2),
    0px 2px 6px rgba(16, 22, 26, 0.2);
  display: flex;
  flex-direction: row;
  height: 52px;
  justify-content: space-evenly;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 19;
}
